<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <ConfirmedEmail />
          </div>
          <div class="mt-3 mb-1">
            <h1>Email Confirmed</h1>
          </div>
          <div>
            <p>
              Thank you for confirming your email.
              Please
              <router-link :to="{ name: 'onboarding-stage-1' }" class="link">
                Click here to proceed
              </router-link>
            </p>
          </div>

        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import KiteIcon from "@/assets/svg/KiteIcon.svg";
import EmailIcon from "@/assets/svg/emailIcon.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg'
import ConfirmedEmail from "@/assets/svg/ConfirmedEmail.svg";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import TurtleRentLogo from "@core/layouts/components/Logo.vue";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import { required } from "@core/utils/validations/validations";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import {
  BAlert,


  BLink,
  BOverlay,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    AuthWrapper,
    ConfirmedEmail,
    KiteIcon,
    AuthenticatorAppIcon,
    EmailIcon,
    BottomDesign,
    TextMessageIcon,
    Rectangles,
    BOverlay,
    BAlert,
    BLink,
    // BAlert,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: '',
      loading: false,
      // validation rules
      required
    }
  },
  computed: {

  },
  methods: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
